.comments-container {
    display: flex;
    flex-direction: column;
}

/* .class-dropdown select {
    padding: 20px;
    background-color: silver;
    border: none;
    border-radius: 5px;
  } */
  
  .comments-portal h2 {
    text-align: center;
  }
  .comments-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    flex: 1 0 calc(33.33% - 20px); 
    max-width: calc(33.33% - 20px); 
    box-sizing: border-box;
  }

  /* Mobile-friendly styles */
@media (max-width: 768px) {
  .comments-card {
    flex: 1 0 calc(100% - 20px); 
    max-width: calc(100% - 20px); 
  }
}