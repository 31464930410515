/* Add this CSS to your existing styles or create a new CSS file */
.reading-container {
    width: 80%; 
    max-width: 400px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the elements horizontally */
    background-color: rgb(209, 203, 171);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .readingForm-body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(111, 133, 111);
  }
  
  .reading-container input,
  .reading-container textarea {
    width: 100%;
    padding: 12px; 
  }
  
  .reading-container form {
    width: 300px; 
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .reading-container label {
    font-weight: bold;
  }
  
  .reading-container textarea {
    resize: vertical; 
  }
  
  